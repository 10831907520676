body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

:root {
	background-color: #f8f1e1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

p {
	font-family: "Poppins", sans-serif;
}


@font-face {
	font-family: "Brewski";
	src: url("../src/assets/Brewski-Original.otf");
}
