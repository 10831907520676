.the-map-header {
    background-image: url("../../assets/mapHeader.jpg");
    background-size: cover;
    background-position: center;
    height: 40vh;
    margin: 0;
}

.root-map {
    background-color: white;
    height: 100vh;
    width: 100vw
}

.page-contents-map {
    padding-top: 5rem;
}

.lot-trace-blindman-logo {
    padding-top: 1rem;
    padding-left: 1rem;
}

.map-container {
    width: 100%;
}
